import React, { Component, Suspense } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../actions';
import Header from './Header';
import Loader from './Loader';
import Footer from './Footer';

const Terms = React.lazy(() => import('./Terms'));
const Landing = React.lazy(() => import('./Landing'));
const Services = React.lazy(() => import('./Services'));
const Signin = React.lazy(() => import('./Signin'));
const Order = React.lazy(() => import('./Order'));
const PushTool = React.lazy(() => import('./pushnotetool'));
const AdminDashboard = React.lazy(() => import('./admin_area/dashboard'));
const UserServices = React.lazy(() => import('./admin_area/Services'));
const ProView = React.lazy(() => import('./admin_area/ProView'));
const ServiceView = React.lazy(() => import('./admin_area/ServiceView'));
const HomeCleaning = React.lazy(() => import('./home-cleaning/HomeCleaning'));
const Carpentery = React.lazy(() => import('./carpentery/Carpentery'));
const Plumbing = React.lazy(() => import('./plumbing/Plumbing'));
const Fumigation = React.lazy(() => import('./fumigation/Fumigation'));
const Electricals = React.lazy(() => import('./electricals/Electricals'));
const Painting = React.lazy(() => import('./painting/Painting'));
const Interiors = React.lazy(() => import('./interiors/Interiors'));
const OfficeCleaning = React.lazy(() => import('./office-cleaning/OfficeCleaning'));
const AcRepairs = React.lazy(() => import('./ac-repairs/AcRepairs'));
const TvInstalls = React.lazy(() => import('./tv-installs/TvInstalls'));
const DishInstalls = React.lazy(() => import('./dish-installs/DishInstalls'));
const WindowRepairs = React.lazy(() => import('./window-repairs/WindowRepairs'));
const WifiSetup = React.lazy(() => import('./wifi-setup/WifiSetup'));
const TheatreSetup = React.lazy(() => import('./theatre-setup/TheatreSetup'));
const GarbageDisposal = React.lazy(() => import('./garbage-disposal/GarbageDisposal'));
const MovingHelp = React.lazy(() => import('./moving-help/MovingHelp'));
const InteriorPainting = React.lazy(() => import('./interior-painting/InteriorPainting'));
const Curtains = React.lazy(() => import('./curtains/Curtains'));
const Pro = React.lazy(() => import('./proform/Pro'));
const About = React.lazy(() => import('./Us'));
const Contact = React.lazy(() => import('./Contact'));
const Help = React.lazy(() => import('./Help'));
const Happiness = React.lazy(() => import('./Happiness'));
const CheckList = React.lazy(() => import('./CheckList'));
const Dashboard = React.lazy(() => import('./Dashboard'));


class App extends Component {
	state = { isLoading: true };

	componentDidMount() {
		this.props.fetchUser().then(() => {
			setTimeout(() => this.setState({ isLoading: false }), 3000);
		});
	}

	render() {
		if (this.state.isLoading)
			return (
				<Loader />
			);
		return (
			<div>
				<BrowserRouter>
					<Suspense fallback={<Loader />}>
						<div>
							<Header />
							<Routes>
								<Route
									exact
									path="/"
									element={<Landing />}
								/>
								<Route exact path="/services" element={<Services />} />
								<Route exact path="/about" element={<About />} />
								<Route exact path="/contact" element={<Contact />} />
								<Route exact path="/help" element={<Help />} />
								<Route exact path="/happiness" element={<Happiness />} />
								<Route exact path="/pro/" element={<Pro />} />
								<Route exact path="/pro/apply" element={<Pro />} />
								<Route exact path="/signin" element={<Signin />} />
								<Route exact path="/order/finalize" element={<Order />} />
								<Route exact path="/terms" element={<Terms />} />
								<Route exact path="/pushnotetool" element={<PushTool />} />
								{this.props.auth.role === "admin" &&
									<div>
										<Route exact path="/admin_area" element={<AdminDashboard />} />
										<Route exact path="/admin_area/pro/view/:id" element={<ProView />} />
										<Route exact path="/admin_area/services" element={<UserServices />} />
										<Route exact path="/admin_area/service/view/:id" element={<ServiceView />} />
									</div>
								}

								<Route exact path="/order/finalize" render={() =>
								(
									(!this.props.auth || localStorage.getItem('service') == null) && (
										<Navigate to="/signin" />
									)
								)} />

								<Route exact path="/dashboard" element={
									this.props.auth.role === "user" ? (
										<Dashboard />
									) : (
										<Navigate to="/signin" />
									)
								} />

								<Route exact path="/signin" element={
									this.props.auth.role === "user" ? (
										<Navigate to="/dashboard" />
									) : (
										<Navigate to="/signin" />
									)
								} />

								{/* <Route exact path="/dashboard" element={<Dashboard />} /> */}
								<Route exact path="/cleaning/entails" element={<CheckList />} />
								<Route exact path="/services/home-cleaning" element={<HomeCleaning />} />
								<Route exact path="/services/carpentery" element={<Carpentery />} />
								<Route exact path="/services/plumbing" element={<Plumbing />} />
								<Route exact path="/services/fumigation" element={<Fumigation />} />
								<Route exact path="/services/electricals" element={<Electricals />} />
								<Route exact path="/services/painting" element={<Painting />} />
								<Route exact path="/services/interiors" element={<Interiors />} />
								<Route exact path="/services/office-cleaning" element={<OfficeCleaning />} />
								<Route exact path="/services/ac-repairs" element={<AcRepairs />} />
								<Route exact path="/services/tv-installs" element={<TvInstalls />} />
								<Route exact path="/services/dish-installs" element={<DishInstalls />} />
								<Route exact path="/services/window-repairs" element={<WindowRepairs />} />
								<Route exact path="/services/wifi-setup" element={<WifiSetup />} />
								<Route exact path="/services/home-theatre-setup" element={<TheatreSetup />} />
								<Route exact path="/services/garbage-disposal" element={<GarbageDisposal />} />
								<Route exact path="/services/moving-help" element={<MovingHelp />} />
								<Route exact path="/services/interior-painting" element={<InteriorPainting />} />
								<Route exact path="/services/curtains" element={<Curtains />} />
							</Routes>

							<Footer />
						</div>
					</Suspense>
				</BrowserRouter>
			</div>
		)
	}

}

function mapStateToProps({ auth }) {
	return { auth }
}

export default connect(mapStateToProps, actions)(App);
