import React, { Component } from "react";
import { Link } from 'react-router-dom';
import logo from './images/footerlogo.png';
import flogo from './images/nextlogo.png';
import appstore from './images/app-store-badge.png';

class Footer extends Component {
	render(){
		return(
			<div>
			 <footer className="" style={{ paddingTop: "50px", paddingBottom: "50px", background: "currentColor"}}>

        <div className="row col-lg-12">
          <div className=" text-center col-lg-3 col-12 col-md">
            <img className="mb-2" src={flogo} alt="" width="30%" />
            <small className="d-block mb-3" style={{ color: "white"}}>&copy; 2023</small>
						<p style={{ color: "white", fontSize: "x-small", padding: 10 }}>Next2you is the easiest and most reliable way to get stuff done around your home.
						Book a professional via our app or website in 60 seconds or less. All professionals on the Next2you platform have been properly vetted for safety
						and quality of service they provide, so you can rest assured.</p>


					</div>
          <div className="col-lg-3 col-6 col-md">
            <h4 style={{ color: "white" }}>Popular Services</h4>
            <ul className="list-unstyled text-small" style={{ lineHeight: 2}}>
              <li><a className="" style={{  textDecoration: 'none', color: "white"}} href="/services/plumbing">Plumbing</a></li>
              <li><a className="" style={{  textDecoration: 'none', color: "white"}} href="/services/home-cleaning">Home Cleaning</a></li>
              <li><a className="" style={{  textDecoration: 'none', color: "white"}} href="/services/painting">Interior Painting</a></li>
              <li><a className="" style={{  textDecoration: 'none', color: "white"}} href="/services/fumigation">Fumigation</a></li>
              <li><a className="" style={{  textDecoration: 'none', color: "white"}} href="/services/electricals">Electricals</a></li>
              <li><a className="" style={{  textDecoration: 'none', color: "white"}} href="/services/moving-help">Moving Help</a></li>
              <li><a className="" style={{ textDecoration: 'none', color: "white"}} href="/services">See all Services</a></li>
            </ul>
          </div>
          <div className="col-lg-3 col-6 col-md">
            <h4 style={{ color: "white" }}>Support & Help</h4>
            <ul className="list-unstyled text-small" style={{ lineHeight: 2}}>
						<li><a className="" style={{ textDecoration: 'none', color: "white"}} href="/terms">Terms and Policies</a></li>
              <li><a className="" style={{ textDecoration: 'none', color: "white"}} href="/services">Book a Service</a></li>
              <li><a className="" style={{ textDecoration: 'none', color: "white"}} href="https://next2you.crunch.help/">Help Center</a></li>
              <li><a className="" style={{ textDecoration: 'none', color: "white"}} href="/happiness">Happiness Guarantee</a></li>
              <li><a className="" style={{ textDecoration: 'none', color: "white"}} href="/contact">Contact Us</a></li>
              <li><a className="" style={{ textDecoration: 'none', color: "white"}} href="/about">About Us</a></li>
							<li><a className="" style={{ textDecoration: 'none', color: "white"}} href="https://next2you.crunch.help/a-guide-to-next2you/what-s-the-cancellation-policy">Cancellation Policy</a></li>

 			</ul>
 			<a href="https://facebook.com/next2youhq" target="_blank" style={{ fontSize: "30px", margin: "3px", color: "white" }}><i className="fa fa-facebook-official" ></i></a>
  		<a href="https://twitter.com/next2you_hq" target="_blank" style={{ fontSize: "30px", margin: "3px", color: "white"  }}><i className="fa fa-twitter-square" ></i></a>
      <a href="https://instagram.com/next2you_hq" target="_blank" style={{ fontSize: "30px", margin: "3px", color: "white"  }}><i className="fa fa-instagram" ></i></a>
			<a href="https://linkedin.com/company/next2you-nigeria" target="_blank" style={{ fontSize: "30px", margin: "3px", color: "white" }}><i className="fa fa-linkedin-square" ></i></a>
			<a href="https://medium.com/@next2you" target="_blank" style={{ fontSize: "30px", margin: "3px", color: "white" }}><i className="fa fa-medium" ></i></a>
			{/* <a href="https://www.youtube.com/channel/UCwzITiK799Vq0giC1o5dQ9A/videos" target="_blank" style={{ fontSize: "30px", margin: "3px", color: "white" }}><i className="fa fa-youtube" ></i></a> */}

      </div>
      <div className=" col-lg-3 col-6 col-md">
             <a style={{margin: "5px", background: "#af22b4", color: "white"}} id="service_link" className="btn" href="/pro">Become a Pro</a>
             <a style={{margin: "5px", background: "#ffff", color: "black"}} id="service_link" className="btn" href="/services/home-cleaning">Book a Cleaner</a>
          	<hr style={{ background: "#ffff"}}/>
						<p style={{ color: "white"}}>
          	No 2, Vistula Close <br/>
            Off Panama Street, Maitama<br/>
            Abuja, Nigeria
						</p>
            <a href="tel: +2348146435568" style={{ textDecoration: "none"}}><h5 style={{ color: "white"}}>+234 814 643 5568</h5></a>
						<a href="mailto: app@next2you.com.ng" style={{ textDecoration: "none"}}><h6 style={{ color: "white"}}>app@next2you.com.ng</h6></a>

							<div className="">
								<a href='https://play.google.com/store/apps/details?id=com.next2you.next2youcustomerapp&hl=en&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
								<img style={{ width: "50%"}} alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/></a>

								<a href='https://apps.apple.com/us/app/id1525235610'>
								<img style={{ width: "50%"}} alt='Get it on App Store' src={appstore}/></a>
							</div>

			 </div>

      </div>



      </footer>
      </div>
		)
	}
}
export default Footer;
