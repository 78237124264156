import axios from 'axios';
import React from 'react';
// import  router from 'react-router';


import { FETCH_USER, FETCH_SERVICE, FETCH_REVIEWS, FETCH_C_BOOKINGS } from './types';

export const fetchUser = () => async dispatch => {
	const res = await axios.get("/app/current_user")
	 dispatch({ type: FETCH_USER, payload: res.data });
};
export const submitCleanForm = (values) => async dispatch => {
	var state = this.state;
	console.log(state);
	const res = await axios.post('/app/home', values);
	//console.log(res.data);
	dispatch({ type: FETCH_USER, payload: res.data});
	//router.push('/success')

}
export const submitCarpForm = (values) => async dispatch => {
	const res = await axios.post('/app/carpentery', values);
	dispatch({ type: FETCH_USER, payload: res.data})
}
export const submitPlumbForm = (values) => async dispatch => {
	const res = await axios.post('/app/plumbing', values);
	dispatch({ type: FETCH_USER, payload: res.data})
}
export const submitElecForm = (values) => async dispatch => {
	const res = await axios.post('/app/electricals', values);
	dispatch({ type: FETCH_USER, payload: res.data})
}
export const submitPaintForm = (values) => async dispatch => {
	const res = await axios.post('/app/painting', values);
	dispatch({ type: FETCH_USER, payload: res.data})
}
export const submitInteriorForm = (values) => async dispatch => {
	const res = await axios.post('/app/interiors', values);
	dispatch({ type: FETCH_USER, payload: res.data})
}
export const submitOCleanForm = (values) => async dispatch => {
	const res = await axios.post('/app/ocleaning', values);
	dispatch({ type: FETCH_USER, payload: res.data})
}
export const submitAcForm = (values) => async dispatch => {
	const res = await axios.post('/app/acrepair', values);
	dispatch({ type: FETCH_USER, payload: res.data})
}
export const submitTvForm = (values) => async dispatch => {
	const res = await axios.post('/app/tvrepair', values);
	dispatch({ type: FETCH_USER, payload: res.data})
}
export const submitDishForm = (values) => async dispatch => {
	const res = await axios.post('/app/dishinstalls', values);

	dispatch({ type: FETCH_USER, payload: res.data})
}
export const submitWindowForm = (values) => async dispatch => {
	const res = await axios.post('/app/windowinstalls', values);
	dispatch({ type: FETCH_USER, payload: res.data})
}
export const submitWifiForm = (values) => async dispatch => {
	const res = await axios.post('/app/wifiinstalls', values);
	dispatch({ type: FETCH_USER, payload: res.data})
}
export const submitTheatreForm = (values) => async dispatch => {
	const res = await axios.post('/app/theatreinstalls', values);
	dispatch({ type: FETCH_USER, payload: res.data})
}
export const submitGarbageForm = (values) => async dispatch => {
	const res = await axios.post('/app/garbageform', values);
	dispatch({ type: FETCH_USER, payload: res.data})
}
export const submitMovingForm = (values) => async dispatch => {
	const res = await axios.post('/app/movingform', values);
	dispatch({ type: FETCH_USER, payload: res.data})
}

export const submitPro = (values) => async dispatch => {
	const res = await axios.post('/app/pro', values);
	dispatch({ type: "apply", payload: res.data})
}

export const submitCurtainForm = (values) => async dispatch => {
	const res = await axios.post('/app/curtainform', values);
	dispatch({ type: FETCH_USER, payload: res.data})
}
export const sendMessage = (values) => async dispatch => {
	await axios.post('/app/sendMessage', values);
	dispatch({ type: "contact" })
}

export const fetchServices = () => async dispatch => {
	const res = await axios.get('/app/fetchServices');
	console.log(res);

	dispatch({ type: FETCH_SERVICE, payload: res.data})
}
export const fetchCompleteBookings = () => async dispatch => {
	const res = await axios.get('/app/fetchCompleteBookings');

	dispatch({ type: FETCH_C_BOOKINGS, payload: res.data})
}
export const handlePayment = (values) => async dispatch => {
	 await axios.post('/app/handlePayment', values);
	//history.push('/dashboard');
	window.location.reload();
	//dispatch({ type: FETCH_SERVICE, payload: res.data})
}

export const addReview = (values) => async dispatch => {
	await axios.post('/app/addReview', values);

	window.location.reload();
	//dispatch({ type: "fetch", payload: res.data })
}

export const fetchReviews = () => async dispatch => {
	const res = await axios.get('/app/fetchReviews');

	dispatch({ type: FETCH_REVIEWS, payload: res.data })
}

export const sendRefMessage = (values) => async dispatch => {
	const res = await axios.post('/app/sendRefMessage', values);
	dispatch({ type: "send_ref", payload: res.data })
}
