//import 'materialize-css/dist/css/materialize.min.css';
//import "bootstrap";
import React from 'react';
import ReactDom from 'react-dom';
import { Provider } from 'react-redux';
import reduxThunk from 'redux-thunk';
import { createStore, applyMiddleware } from 'redux';
import HttpsRedirect from 'react-https-redirect';
import App from './components/App';
import reducers from './reducers/';
import "./css/page.css";
import axios from 'axios';
window.axios = axios;

const store = createStore(reducers, {}, applyMiddleware(reduxThunk));

ReactDom.render(
	<Provider store={store}>
	 <HttpsRedirect>
		<App />
	 </HttpsRedirect>
	</Provider>,
	document.querySelector('#root')
);
