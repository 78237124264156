import React, { Component } from 'react';

class Loader extends Component {
	render() {
		return (
	    <div id="preloader">
         <div id="loader"></div>
      </div>
		)
	}
}

export default Loader;