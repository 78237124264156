import { combineReducers } from 'redux';
import {reducer as reduxForm, actionTypes } from 'redux-form';
import authReducer from './authReducer';
import serviceReducer from './serviceReducer';
import bookingsReducer from './bookingsReducer';
import reviewsReducer from './reviewsReducer';

export default combineReducers({
	auth: authReducer,
	form: reduxForm,
	submitSucceeded: (submitSucceeded = false, action) => {
		switch (action.type) {
			case actionTypes.TOUCH: 
			    return false;
			case actionTypes.START_SUBMIT:
			    return false;
			case actionTypes.STOP_SUBMIT:
			    return action.errors ? false : true;
			default: 
			    return submitSucceeded;
		}
	},
	services: serviceReducer,
	bookings: bookingsReducer,
	reviews: reviewsReducer
})