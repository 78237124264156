import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
//import $ from 'jquery';
import logo from './images/nextlogo.png';
class Header extends Component {

  renderContent() {
    switch(this.props.auth) {
      case null:
         return;
      case false:
         return <li className="nav-item">
              <a id="service_link" style={{ color: 'white'}} className="nav-link js-scroll-trigger" href="/signin">Sign In</a>
            </li>
            {/*<li className="nav-item">
              <a id="service_link" style={{ color: 'white'}} className="nav-link js-scroll-trigger" href="/auth/google">Sign In</a>
            </li>*/}
      default:
        return [
        <li key="1" className="nav-item">
              <a id="service_link" style={{ color: 'white'}} className="nav-link js-scroll-trigger" href="/dashboard">Bookings</a>
         </li>,
        <li key="2" className="nav-item">
              <a id="service_link" style={{ color: 'white'}} className="nav-link js-scroll-trigger" href="/app/logout">Logout</a>
            </li>
          ];

    }
  }
	render(){
    if(this.props.auth && this.props.auth.role == "admin")
      return (
           <div>
              <nav class="navbar navbar-expand-sm bg-dark navbar-dark fixed-top">
              <div className="container">
                <a href="/" style={{width: "130px"}} className="navbar-brand js-scroll-trigger"><img  alt="" style={{ width: "100%"}} src={logo} /></a>
                <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
              <li style={{ color: "white"}} className="fa fa-bars"></li>
                </button>
                <div className="collapse navbar-collapse" id="navbarResponsive">
                  <ul className="navbar-nav text-uppercase ml-auto">
                    <li className="nav-item">
                      <a id="service_link" style={{ color: 'white'}} className="nav-link js-scroll-trigger" href="/admin_area"><i class="icon-dashboard icon-3"></i> Dashboard</a>
                    </li>
                    <li className="nav-item">
                      <a id="service_link" style={{ color: 'white'}} className="nav-link js-scroll-trigger" href="/admin_area/services"><i class="icon-th-list icon-3"></i> Services</a>
                    </li>
                    <li className="nav-item">
                      <a id="service_link" style={{ color: 'white'}} href="/app/logout" className="nav-link js-scroll-trigger" to="/about"><i class="icon-signout icon-3"></i> Sign Out</a>
                    </li>
                  </ul>
                </div>
              </div>
              </nav>
           </div>
      );

		return (
	<div>
    <nav style={{ background: "#8629b5", padding: 13}} className="navbar navbar-expand-lg navbar-dark " id="mainNav">
      <div className="container">
        <a href="/" style={{width: "130px"}} className="navbar-brand js-scroll-trigger"><img  alt="" style={{ width: "100%"}} src={logo} /></a>
        <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
     	<li style={{ color: "white"}} className="fa fa-bars"></li>
        </button>
        <div className="collapse navbar-collapse" id="navbarResponsive">
          <ul className="navbar-nav text-uppercase ml-auto">
            <li className="nav-item">
              <a id="service_link" style={{ color: 'white'}} className="nav-link js-scroll-trigger" href="/services">Services</a>
            </li>
            <li className="nav-item">
              <Link id="service_link" style={{ color: 'white'}} className="nav-link js-scroll-trigger" to="/about">About</Link>
            </li>
            <li className="nav-item">
              <a id="service_link" style={{ color: 'white'}} className="nav-link js-scroll-trigger" href="https://next2you.crunch.help/">help</a>
            </li>
            {this.renderContent()}
            <li className="nav-item">
              <Link id="service_link" style={{ color: 'white'}} className="btn" style={{ background: "#f8af34", color: "white"}} to="/pro">Become a Pro</Link>
            </li>

          </ul>
        </div>
      </div>
    </nav>
    </div>

		);
	}
}

function mapStateToProps({ auth }) {
	return { auth };
}

export default connect(mapStateToProps)(Header);
